//布局
.bg-wrap {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.wrap-1024 {
  width: 1024px;
  margin: 0 auto;
  position: relative;
}

.wrap-934 {
  width: 934px;
  margin: 0 auto;
}

.pdb118 {
  padding-bottom: 120px
}

//颜色
@mixin gray($value:100%) {
  -webkit-filter: grayscale($value);
  -moz-filter: grayscale($value);
  -o-filter: grayscale($value);
  filter: grayscale($value);
  filter: gray;
}

@mixin gradient($top,$bottom) {
  background: $bottom; /* 一些不支持背景渐变的浏览器 */
  background: -moz-linear-gradient(top, $top, $bottom);
  background: -webkit-linear-gradient(top, $top, $bottom);
  background: -o-linear-gradient(top, $top, $bottom);
}

//字体
.f-blue {
  color: #2981ce;
}

.f-black {
  color: #3b3b3b;
}

.f-white {
  color: white;
}

.txt-intro {
  line-height: 1.5;
  color: #fff;
  .main-title {
    font-size: 36px;
  }
  .sub-title {
    font-size: 24px;
  }
  .small-title {
    font-size: 16px;
    height: 1.5;
  }
}

.txt-panel {
  text-align: center;
  .main-title {
    font-size: 30px;
    font-weight: bold;
  }
  .sub-title {
    font-size: 24px;
    line-height: 2;
  }
}

//三角
@mixin arw-down($color) {
  position: absolute;
  border-top: 8px solid $color;
  border-right: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid transparent;
  font-size: 0;
}

//头部
.nav {
  height: 66px;
  line-height: 66px;
  h1 {
    float: left;
    width: 150px;
    height: 100%;
    background: url(../images/logo.png) no-repeat 0 10px;
    background-size: 120px 41px;

  }
  .nav-right {
    float: right;
    text-align: center;
    ul {
      li {
        float: left;
        a {
          display: block;
          height: 62px;
          width: 96px;
          margin-left: 4px;
          line-height: 62px;
          border-top: 4px solid transparent;
          color: #fff;
          font-size: 14px;
          &:hover {
            border-color: #fff;
          }
        }
        &.selected a {
          border-color: #fff;
        }
      }
    }
  }
}

//尾部
.footer {
  background: url(../images/footer-bg.jpg);
  height: 216px;
  .wrap-1024 {
    margin-bottom: 52px;
    .footer-left {
      float: left;
      margin-top: 74px;
      position: relative;
      .code-line-header {
        position: relative;
        cursor: pointer;
      }
      .code-line-body{
        position: relative;
        cursor: pointer;
        top: 8px;
        left: -4px;
      }
      img:hover ~.code-line {
        opacity: 1;
      }
      .code-line {
        opacity: 0;
        transition: 1s all; //???
        position: absolute;
        top: -92px;
        left: 94px;
        .image-wrap {
          float: left;
          width: 94px;
          margin-right: 44px;
          position: relative;
          &:after {
            @include arw-down(#fff);
            content: '';
            bottom: -16px;
            left: 39px;
          }
          img {
            width: 100%;
          }

        }
      }
    }
  }
  .footer-right {
    float: left;
    margin-top: 62px;
    color: #fff;
    font-size: 14px;
    ul {
      float: right;
      margin-bottom: 30px;
      li {
        float: left;
        &:nth-of-type(1) {
          a {
            border: none;
            padding-left: 0;
          }
        }
        &:last-child {
          a {
            padding-right: 0px;
          }
        }
        a {
          display: block;
          padding-left: 12px;
          padding-right: 12px;
          border-left: 1px solid #fff;
          color: #fff;
        }
      }
    }
    p {
      float: left;
      line-height: 1.8;
      padding-left: 154px;
      font-size: 12px;
      a{
        color:#fff;
        text-decoration:underline;
      }
    }
  }
}



//Boby to be页面
.btb-banner {
  background: url(../images/BTB-banner.jpg) no-repeat;
  background-size: 100% 100%;
  height: 524px;

  .txt-intro {
    margin: 150px 0 0 130px;
    .main-title {
      margin-bottom: 25px;
    }
  }
}

.btb-menu-ul {
  li {
    width: 25%;
    float: left;
    text-align: center;
    height: 261px;
    font-size: 14px;
    img {
      margin: 40px 0 13px;
    }
    .f-blue {
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
}

.btb-icon-warp {
  background: #f2f2f2;
  height: 634px;
  .wrap-934 {
    height: 100%;
    background: url(../images/btn-iphone.png) no-repeat center 220px;
    .txt-panel {
      padding-top: 35px;
    }
    ul {
      li {
        float: right;
        line-height: 70px;
        img {
          vertical-align: middle;
        }
        span {
          font-size: 14px;
        }
        &:nth-of-type(1) {
          margin-top: 66px;
        }
        &:nth-of-type(2) {
          margin-top: 26px;
          padding-right: 76px;
        }
        &:nth-of-type(3) {
          margin-top: 56px;
          padding-right: 76px;
        }
        &:nth-of-type(4) {
          margin-top: 26px;
        }

      }
    }
    .btb-icon-left {
      float: left;
      width: 306px;
      margin-left: 46px;
      ul {
        li {
          span {
            padding-right: 20px;
          }
        }
      }
    }
    .btb-icon-right {
      float: left;
      width: 356px;
      margin-left: 226px;
      ul {
        li {
          float: left;
          span {
            padding-left: 20px;
          }
          &:nth-of-type(2) {
            padding-right: 0px;
            padding-left: 76px;
          }
          &:nth-of-type(3) {
            padding-right: 0px;
            padding-left: 76px;
          }
          img {
            float: left;
          }
        }
      }
    }
  }
}

.btb-course-wrap {
  background: url(../images/btb-train.jpg) no-repeat;
  background-size: 100% 100%;
  height: 502px;
  .wrap-934 {
    padding-top: 96px;
  }
}

.btb-app-wrap {
  height: 680px;
  text-align: center;
  #tabs {
    margin-top: 46px;
    margin-left: 126px;
    margin-bottom: 60px;
    li {
      float: left;
      margin-right: 100px;
      a {
        display: block;
        width: 154px;
        height: 42px;
        line-height: 42px;
        border: 2px solid #cbcbcb;
        background: #fff;
        border-radius: 6px;
        color: #cbcbcb;
        font-size: 18px;

      }
      &.active, &:hover {
        a {
          border: 2px solid #2981ce;
          background: #2981ce;
          color: white;
        }
      }

    }
  }
  .content {
    margin-top: 64px;
    ul {
      li {
        width: 25%;
        float: left;
        text-align: center;
        &:nth-of-type(1) {
          text-align: left;
        }
        &:nth-of-type(2) {
          position: relative;
          right: 5px;
        }
        &:nth-of-type(3) {
          position: relative;
          left: 5px;
        }
        &:nth-last-child(1) {
          text-align: right;
        }
        img {
          box-shadow: 3px 3px 5px #ccc;
          width: 200px;
        }

      }
    }
  }

}

.btb-download-wrap {
  background: url(../images/btb-download.jpg) no-repeat;
  background-size: 100% 100%;
  height: 516px;
  .txt-panel {
    padding-top: 140px;
    text-align: left;
    padding-bottom: 35px;
  }
  .btb-code-wrap {
    margin-top: 60px;
    .code-image-wrap {
      float: left;
      width: 140px;
      img {
        width: 100%;
      }
    }
    .code-btn-wrap {
      float: left;
      margin-right: 12px;

      width: 240px;
      a {
        width: 230px;
        height: 60px;
        line-height: 60px;
        font-size: 18px;
        border: 2px solid #2981ce;
        border-radius: 6px;
        text-indent: 94px;
        &.iphone {
          margin-bottom: 12px;
          background: url(../images/iphone.png) no-repeat 35px center;
        }
        &.andorid {
          background: url(../images/andorid.png) no-repeat 35px center;
        }
      }
    }
  }
}

//index页面
.index-banner {
  background: #fff url(../images/index-banner.jpg) no-repeat;
  background-size: 100% 423px;
  height: 660px;
  .nav {
    h1 {
      background: url(../images/index-logo.png) no-repeat 0 10px;
    }
    .nav-right {
      ul {
        li {
          a {
            color: #585858;
            &:hover {
              color: #02327f;
              border-color: #02327f;
            }
          }
          &.selected a {
            color: #02327f;
            border-color: #02327f;
          }
        }
      }
    }
  }
  .txt-intro {
    margin: 316px 0 64px 0;
    .main-title {
      color: #0155a0;
      text-align: center;
      text-shadow: 1px 1px white,
      2px 2px white,
      3px 3px rgba(197, 223, 248, 0.8),
      4px 4px rgba(197, 223, 248, 0.8);
    }
  }
}

.index-text1-wrap {
  background: url(../images/index-txt-bg1.jpg) no-repeat;
  background-size: 100% 100%;
  height: 230px;
  .wrap-934 {
    padding: 83px 0;
    p {
      font-size: 16px;
      line-height: 24px;
      color: #fff;
    }
  }
}

.index-ssdm-wrap {
  height: 572px;
  .wrap-934 {
    background: #fff url(../images/index-iphone.png) no-repeat 500px 182px;
    .ssdm-info {
      padding-top: 62px;
      width: 468px;
      height: 330px;

      h2 {

      }

    }
  }

}

.index-btb-wrap {
  height: 532px;
  background: #f5f5f5 url(../images/index-iphone2.png) no-repeat 100px bottom;
  .wrap-934 {

    .btb-info {
      padding-top: 62px;
      width: 468px;
      height: 308px;
      float: right;
      h2 {
        text-align: right;
      }
      h3 {
        text-align: right;
      }
      h4 {
        text-align: right;
      }
      a.btn {
        float: right;
      }
    }
  }
}

.index-ssdm-wrap .app-info, .index-btb-wrap .app-info {
  h2 {

    font-size: 30px;
    line-height: 48px;
    font-weight: bold;
    color: #2981ce;
  }
  h3 {
    font-size: 24px;
    font-weight: bold;
    color: #2981ce;
    line-height: 60px;
  }
  h4 {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.5;
    color: #5b5b5b;
    margin-bottom: 25px;
  }
  p {
    font-size: 14px;
    line-height: 18px;
    color: #5a5a5a;
    margin-bottom: 10px;
  }
  a.btn {
    width: 286px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    font-size: 12px;
    color: white;
    background: #2fb7cf;
    margin-top: 20px;
    border-radius: 0;
    span {
      text-decoration: underline;
    }
  }

}

.index-text2-wrap {
  background: url(../images/index-txt-bg2.jpg) no-repeat;
  background-size: 100% 100%;
  height: 512px;
  .wrap-934 {
    background: url(../images/txt-cir.png) no-repeat 0 270px;
  }
  .txt-panel {
    color: white;
    padding-top: 29px;
    margin-bottom: 20px;
    .main-title {
      text-align: center;
      margin-bottom: 1px;
    }
    .sub-title {
      text-align: center;
      margin-bottom: 2px;
      font-weight: bold;
    }
    .small-title {
      line-height: 30px;
      text-align: left;

    }
  }
}

.index-news-wrap {
  height: 650px;
  .wrap-1024 {
    padding-top: 75px;
  }

}

.partner-wrap {
  background: #f6f6f6;
  height: 235px;
  .wrap-934 {
    padding-top: 36px;
    .txt-panel {
      padding-bottom: 22px;
    }
    .partner-box {
      img {
        margin-right: 30px;
        vertical-align: bottom;
      }
    }
  }
}

//联系我们

.contact-banner {
  background: url(../images/contact-banner.jpg) no-repeat;
  background-size: 100% 100%;
  height: 524px;
  .txt-intro {
    margin: 150px 0 0 130px;
    .main-title {
      margin-bottom: 28px;
    }
  }
}

.contact-info-wrap {
  height: 412px;
  padding-top: 30px;
  .info-box {
    margin-bottom: 16px;
    .info-title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .info-detail p {
      font-size: 16px;
      line-height: 36px;
    }
  }
}

//加入我们

.join-banner {
  background: url(../images/join-banner.jpg) no-repeat;
  background-size: 100% 100%;
  height: 524px;
  .txt-intro {
    margin: 140px 0 0 130px;
    .main-title {
      margin-bottom: 28px;
    }
  }

}

.gete-info-wrap {
  text-align: center;
  height: 390px;
  p.f-black {
    padding: 28px 0 28px;
    font-size: 16px;
    line-height: 1.65;
  }
  p.f-blue {
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
  }
}

.join-news-wrap {
  height: 712px;
  background: #f2f2f2;
  p.title {
    font-size: 36px;
    height: 147px;
    line-height: 147px;
    text-align: center;
    color: #2981ce;
    font-weight: bold;
  }
}

.join-txt-wrap {
  height: 445px;

  background: url(../images/join-text.jpg) no-repeat;
}
.join{
  .unslider-arrow:hover{
    top:222px;
  }
  .unslider-arrow{
    top:222px;
  }
}

.wrap-934 {
  a.btn {
    width: 450px;
    height: 56px;
    line-height: 56px;
    font-size: 16px;
    color: #fff;
    border-radius: 4px;
    background: #2981ce;
    text-align: center;
    margin-bottom: 52px;
  }
}

//ssdm页面

.ssdm-banner {
  background: url(../images/ssdm-banner.jpg) no-repeat;
  background-size: 100% 100%;
  height: 524px;
  .txt-intro {
    margin: 140px 0 0 130px;
    .main-title {
      margin-bottom: 28px;
    }
  }
}

.ssdm-menu {
  padding-top: 64px;
  height: 276px;
  li {
    float: left;
    font-size: 14px;
    margin-right: 98px;
    &:nth-last-child(1) {
      margin-right: 0;
    }
    img {
      margin-bottom: 5px;
    }
    p.t1 {
      line-height: 35px;
      font-weight: bold;
      color: #2981ce;
    }
    p.t2 {
      @extend .f-black;
    }
    p.t3 {
      @extend .f-black;
      font-weight: bold;
    }
  }

}

.ssdm-app {
  height: 1700px;
  ul {
    li {
      height: 331px;
      border-bottom: 1px solid #dcdcdc;
      &:nth-of-type(1) {
        img {
          padding-top: 60px;
        }
      }
      &:nth-last-child(1) {
        border: none;
      }
      .txt-box {

        padding-top: 25px;
        .t1 {
          font-size: 24px;
          line-height: 2.5;
          color: #000;
        }
        .t2 {
          font-size: 14px;
          line-height: 24px;
          color: #000;

        }
        .t3 {
          font-size: 14px;
          line-height: 24px;
          color: #00a0e9;
        }
      }
      img {
        padding-top: 50px;
      }
    }
  }
}

//弹框
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  visibility: hidden;
  opacity: 0;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  transition: all 0.3s;
}

.bomb-box {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 842px;
  height: 388px;
  border-radius: 4px;
  background: #fff;
  z-index: 2000;
  visibility: hidden;
  transform: translateX(-50%) translateY(-50%);

  ul {
    padding-top: 40px;
    text-align: center;
    width: 726px;
    margin: 0 auto;
    li {
      float: left;
      width: 144px;
      margin-right: 50px;
      &:nth-last-child(1) {
        margin-right: 0px;
      }
      p.down-title {
        font-size: 24px;
        line-height: 30px;
        color: #515151;
        margin-bottom: 15px;
      }
      img {
        width: 140px;
        border: 1px solid #515151;
        margin-bottom: 20px;
      }
      .down-btn-group {
        a.btn {
          width: 140px;
          height: 38px;
          line-height: 38px;
          padding: 0;
          font-size: 16px;
          text-indent: 34px;
          border: 1px solid #2981ce;
          margin-bottom: 8px;
          color: #2981ce;
        }
        a.iphone {
          background: url(../images/iphone.png) no-repeat 14px 6px;
          background-size: 22px 25px;
        }
        a.andorid {
          background: url(../images/andorid.png) no-repeat 14px 6px;
          background-size: 22px 25px;
        }
      }
    }
  }

  img.close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
}

.mask-show {
  opacity: 1;
  visibility: visible;
}

.mask-show ~ .bomb-box {
  opacity: 1;
  visibility: visible;
}

#back-to-top {
  position: fixed;
  display: none;
  bottom: 20px;
  left: 90%;
  //right:10%;
  a {
    text-align: center;
    text-decoration: none;
    display: block;
    width: 69px;
    height: 69px;
    background: url(../images/back-to-top.png) no-repeat;
    //transition: all 1s;
    //margin-left: 550px;
  }
}